.viewimage-container {
  width: 75%;
  margin: 50px auto;
  position: relative;

}

.custom-spinner {
  color: #c1272d !important;
}

.loading-text {
  font-size: 1.2rem;
  font-weight: 600;
  color: #c1272d;
  text-align: center;
  margin-top: 10px;
}

.viewimage-body {
  border: 1px solid #c1272d;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Adds a smooth shadow */
}


.modal-nav-button-v {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px; /* Adjust the size of the button */
  height: 50px; /* Adjust the size of the button */
  border-radius: 50%; /* Makes the button circular */
  background-color: #ffffff; /* Button background color */
  border: none;
  border: 1px solid #c1272d;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.modal-nav-button-v.left {
  left: -100px; /* Adjust as needed */
}

.modal-nav-button-v.right {
  right: -100px; /* Adjust as needed */
}

.arrow-icon-v {
  width: 15px; /* Adjust as needed */
  height: 15px;
  margin-right: 5px;
  filter: invert(38%) sepia(82%) saturate(748%) hue-rotate(349deg) brightness(94%) contrast(87%);
}

.arrow-icon-v.left {
  transform: rotate(180deg);
  margin-left: 5px; /* Rotates the arrow to point left */
}

.arrow-icon-v.right{
  margin-left: 7px;
}
.modal-nav-button-v:hover{
  border: 1px solid #ffffff;
  background-color: #c1272d;
}
.modal-nav-button-v:hover .arrow-icon-v {
  filter: invert(100%); /* Reset the filter when hovering over the button */
}

.res-change{    
  border-radius: 5px;
  box-sizing: border-box;
  color: #c1272d;
  cursor: pointer;
  font-family: Inter, sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  outline: none;
  background-color: #f8f8f8;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s;
  white-space: nowrap;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

input[type="number"] {
  width: 50% !important;
  text-align: center;
  border: 1px solid #c1272d !important;
}

button.button-35{
  background: #c1272d !important;
  width: 50%;
}


.button-35:hover {
  transform: scale(0.9); /* Shrink a slightly on hover */
}

.color-palette {
  display: flex;
  gap: 10px;
  margin-top: 16px;
  padding-top: 10px;
}

.chrome-picker{
  box-shadow: none !important;
  width: 100% !important;
}

.color-box {
  width: 16px;
  height: 16px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #c1272d;
  transition: border 0.3s ease-in-out;
}

.color-box:hover {
  border: 2px solid #000;
}


.color-picker-container {
  display: flex;
  flex-direction: column;
  width: 250px;
  padding: 10px;
  border-radius: 4px;
  gap: 16px;
}

.color-picker-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: white;
}



.quick-color-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  position: relative;
  top: -25px;
  padding-left: 3px;
  padding-right: 0px;
}

.color-box:hover {
  border: 2px solid #000;
}

/* Transparent color circle effect */
.color-box.transparent {
  background-color: transparent;
  background-image: 
    linear-gradient(45deg, #e7e7e7 25%, transparent 25%, transparent 75%, #e7e7e7 75%, #e7e7e7), 
    linear-gradient(45deg, #e7e7e7 25%, transparent 25%, transparent 75%, #e7e7e7 75%, #e7e7e7);
  background-size: 8px 8px;  /* Small grid size to give the checkerboard effect */
  background-position: 0 0, 4px 4px;  /* Shifted to create the grid pattern */
  background-repeat: repeat; /* Repeat the background to cover the circle */
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-box.transparent::after {
  content: '';
  width: 12px; /* Adjust size for the visual effect */
  height: 12px; /* Adjust size for the visual effect */
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #e7e7e7; /* Optional: Optional border for clarity */
}

/* Targeting the specific color picker area */
.color-picker-container .chrome-picker .saturation,
.color-picker-container .chrome-picker .hue,
.color-picker-container .chrome-picker .alpha {
  cursor: crosshair !important; /* Force the cursor to crosshair */
}


.reset-div{
  width: auto !important;
}
.reset-btn {
  background-color: #fff; /* or your desired color */
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  box-sizing: border-box;
  border: 1px solid #c1272d;
}

.reset-btn img {
  width: 30px; /* Adjust size as needed */
  height: 30px;
}


.image-preview-container img, .image-preview-container svg {
  margin-top: 0px;
  margin-bottom: 0px;
}


.main-con {
  display: flex;
  align-content: stretch;
  align-items: flex-start;

}

.selection-container {
  flex-wrap: wrap; /* Allow wrapping on small screens */
  justify-content: space-between; /* Space between items */
  width: 49%;
  padding-top: 20px;
}

.res-sec {
  flex: 1; /* Allow sections to take equal space */
  min-width: 200px; /* Minimum width to prevent too much shrinkage */
  display: flex; /* Make them flex containers */
  flex-direction: column; /* Align items vertically */
}

.res-sec h3{
  padding-left: 0px !important;
}

/* Add this to your CSS file */
@keyframes rotate360 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.rotate-img {
  animation: rotate360 1s ease-in-out;
}


.rotate-img {
  animation: rotate360 0.5s ease-in-out;
}

.format-selection {
  flex: 1; /* Allow sections to take equal space */
  min-width: 200px; /* Minimum width to prevent too much shrinkage */
  display: flex; /* Make them flex containers */
  flex-direction: column; /* Align items vertically */
  margin-top: 10px;
}

.format-selection h3{
  padding-left: 5px !important;
}

.format-selection a.button-35{
  border: 1px solid #e7e7e7;
}

.or-spacer { 
  margin: 10px auto;
  width: 100%;
  height: 1px; /* Adjust the thickness of the line here */
  background-color: #e7e7e7; /* Change this color to whatever you prefer */
  position: relative;
}

.modal-header {
  background-color: #fdfdfd;
  color: black;
  padding: 15px 20px !important;
  border-bottom: 1px solid #e7e7e7 !important;
}

.modal-content{
  width: 75% !important;
  margin-left: 14.5%;
}

.modal-title {
  font-size: 1rem !important;
  font-weight: 500;
}
.b-35 {
  margin-top: 10px;
  padding: 0; /* Remove default padding */
  list-style-type: none; /* Remove bullet markers */
  width: 100%;
  justify-content: space-between;
  align-items: center; /* Center items vertically */
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  gap: 10px; /* Optional spacing between buttons */
}

.b-35l{
  margin: 1px 0px;
  padding: 0; /* Remove default padding */
  list-style-type: none; /* Remove bullet markers */
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.b-352{
  margin-top: 1px;
  padding: 0; /* Remove default padding */
  list-style-type: none; /* Remove bullet markers */
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 0px;
}


#lst-r{
  padding-bottom: 0px;
}

.res-sec .b-35{
  background-color: #f8f8f8;
  border-radius: 5px;
}
.res-sec .b-35 .button-35{
  background-color: #f8f8f8;
}
.button-35 {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
  box-sizing: border-box;
  color: #121212;
  cursor: pointer;
  font-size: 0.8rem; /* Reduced font size */
  
  justify-content: center;
  line-height: 1;
  margin: 5px; /* Add margin for spacing between buttons */
  outline: none;
  padding: 5px 10px; /* Reduced padding for smaller buttons */
  text-align: center;
  text-decoration: none;
  transition: all 0.2s; /* Transition for smooth effect */
  white-space: nowrap;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-35:hover {
  box-shadow: none; /* No shadow on hover for unclicked buttons */
}

.button-35.active {
  background-color: #fdfdfd !important; /* Change background color for active button */
  transform: scale(1.02); /* Slightly increase size of selected button */
  border: 1px solid #c1272d !important;
  color: #c1272d;
}


button.btn {
  background-color: black;
}
.modal-body {
  display: flex;
  padding: 0px 20px !important;
  padding-bottom: 15px !important;
}

button.btn-close {
  font-size: 0.9rem;
}

.image-modal-container-v {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
}
.img-color{
  padding: 2%;
  padding-top: 0px;
}
.image-preview-container {
  height: 30%;
  overflow: hidden;
  margin: 0px auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-preview-container img,
.image-preview-container svg {
  width: 100%;
  height: 100%;
}

.content-section {
  display: flex;
  padding: 15px 0px;
  align-items: center ;
}

.content-section h3{
  margin-right: 2%;
}

.edit-more-colors{
  font-size: 0.75rem;
}

.customize-label {
  font-weight: 500;
  width: 20%;
}

.bg-col{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.color-picker-row {
  display: flex;
}

.bg-col, .color-picker-row {
  display: flex;
  width: 100%;
}

.color-circle {
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 0px;
  margin-right: 5px;
  box-sizing: border-box;
  border: 1px solid #c1272d;
}

.bg-color-circle {
  width: 30px;
  height: 30px;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #c1272d;
  transition: width 0.3s ease, height 0.3s ease;
}


.separator {
  width: 1px;
  height: 30px;
  background-color: #e7e7e7;
  margin: 0 10px;
}
.separator2{
  background-color: #e7e7e7;
  height: 10rem;
  margin: 15px 20px;
  width: 1px;
}

a.edit-more-colors{
  font-size: 1rem;
}

/* Responsive behavior */


h3 {
  font-size: 0.8rem !important;
  font-weight: bold !important;
  margin-bottom: 0px !important;
}


.color-picker-container {
  position: absolute; /* Keep it absolute to allow positioning */
  z-index: 1000; /* Make sure it's on top */
  margin-left: 0;
}

.download-section h2 {
  margin-bottom: 25%;
  font-size: 2.5vh;
  margin-top: 25%;
}

.download-section button {
  margin-bottom: 10px;
  width: 100%;
  font-size: 1rem;
}

.download-section button.btn.btn-primary,
.download-section button.btn.btn-success,
.download-section button.btn.btn-warning {
  background-color: #c1272d; /* Set the background color */
  color: white; /* Set the text color */
}

/* Optional: Change button color on hover */
.download-section button.btn.btn-primary:hover,
.download-section button.btn.btn-success:hover,
.download-section button.btn.btn-warning:hover {
  background-color: #a0212a; /* Darken color slightly on hover */
  color: white; /* Keep text color white */
}


.colors {
  display: flex;
  flex-wrap: wrap;
  gap: 8px; /* Reduced gap for better alignment */
  width: 85%;
  align-items: flex-start;
}

.reset-div{
  width: 50%;

}

.tag-sec {
  padding-top: 20px;
  width: 49%;
}

.tag {
  display: inline-block;
  background-color: #f8f8f8;
  color: black;
  padding: 2px 10px;
  margin-bottom: 5px;
  border-radius: 50px;
  margin: 5px !important;
  font-size: 0.8rem;
  transition: background-color 0.3s ease;
}

.tags-container {
  margin-top: 10px;
  padding-left: 0px;
  flex-wrap: wrap; /* Allow tags to wrap on smaller screens */
}

.tag:hover {
  background-color: #fdfdfd;
  transform: scale(1.02);
}

.tag-link {
  text-decoration: none;
  color: black;
}

.see-more {
  display: inline-block;
}


.inverted {
  transform: rotate(180deg); /* Rotates the icon */
}
.see-more-btn {
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 7px;
  font-size: 0.6rem;
}

.button-29 {
  align-items: center;
  appearance: none;
  background: #c1272d;
  border-radius: 5px;
  
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-flex;
  border: 0;
  height: 36px; /* Reduced height */
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 12px; /* Reduced padding */
  padding-right: 12px; /* Reduced padding */
  position: relative;
  text-align: left;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  font-size: 1rem; /* Reduced font size */
  font-weight: 500;
}


.button-29:hover {
  background: #9F1C23;
}

.slider-container {
  position: relative;
  height: 100px;
  display: flex; /* Make it a flex container */
  justify-content: center; /* Center the arrows and images */
  align-items: center; /* Vertically align arrows and images */
  margin: 0 auto; /* Center the container itself */
  width: 100%; /* Adjust width as needed */
}

/* Arrows */
.arrow {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px; /* Adjust size as needed */
  padding: 10px; /* Add padding for a larger click area */
  z-index: 1; /* Ensure arrows are above the images */
}

.arrow:hover {
  opacity: 0.7; /* Slightly dim on hover */
}


@media (max-width: 405px) {
  .arrow-icon {
    width: 15px;
    height: 15px;
  }
  .tag{
    font-size: 0.75rem;
  }
  .see-more-btn{
    font-size: 0.75rem;
  }
  .colors{
    width: 100% !important;
  }
  .bg-color-circle{
    width: 30px !important;
  }
}



/* For screens with max-width of 500px */
@media (max-width: 520px) {
  .content-section{
    padding: 5px 0px;
  }
  .main-con{
    flex-direction: column;
  }
  .selection-container{
    width: 100%;
    padding: 5px 0px;
  }
  .button-29{
    margin-bottom: 0px;
  }
  .separator2{
    display: none;
  }
  .or-spacer{
    display: flex !important;
  }
  .tag-sec{
    padding: 5px 0px;
    width: 100%;
  }
  .image-preview-container {
    height: 20%;
    width: 100% !important;
    margin-bottom: 5%;
  }
  .content-section {
    height: auto;
    width: 100% !important;
  }
  .color-picker-container {
    width: 150px !important;
  }
  .color-box{
    width: 15px;
    height: 15px;
  }
  .download-section button {
    font-size: 0.75rem;
  }
  h3, .tag {
    line-height: 1;
  }
  .modal-body {
    padding: 2% !important;
  }
  .customize-label {
    font-size: 0.85rem;
    margin-right: 5px;
  }
}

/* For screens with max-width of 785px */
@media (max-width: 785px) {
  .modal-title{
    font-size: 0.75rem !important;
  }
  .button.btn-close{
    font-size: 0.75rem;
  }
  .content-section {
    display: block;
  }
  .modal-content {
    width: 100% !important;
    margin-left: 0px;
  }
  .customize-label {
    font-size: 0.9rem;
    margin-right: 8px;
  }
  a.edit-more-colors {
    font-size: 0.5rem;
  }

  .color-circle{
    width: 25px;
    height: 25px;
  }
  .bg-color-circle{
    width: 25px;
    height: 25px;
  }
  .reset-btn{
    width: 25px;
    height: 25px;
  }
}

/* For screens with max-width of 992px */
@media (max-width: 992px) {
  .selection-container {
    flex-direction: column;
  }
  .res-sec, .b-35 {
    width: 100% !important;
  }
  .res-sec h3 {
    padding-left: 0px !important;
  }
  .format-selection h3 {
    padding-left: 0px !important;
  }
  .format-selection {
    width: 100%;
    margin-top: 10px;
  }
  .format-selection .b-35 {
    margin-top: 0px;
  }
  .button-35 {
    flex: 1 1 auto;
  }
}

/* For screens with max-width of 1000px */
@media (max-width: 1000px) {
  .image-preview-container {
    height: 30%;
    width: 100% !important;
    margin-bottom: 5%;
  }
  .content-section {
    width: 100% !important;
  }
  .modal-content {
    width: 90% !important;
    margin-left: 5%;
  }
  .colors{
    gap : 1px
  }
  a.button-35{
    font-size: 0.60rem;
  }
}

/* For screens with max-width of 1024px */
@media (max-width: 1024px) {
  .modal-title{
    font-size: 0.9rem !important;
  }
  .customize-label {
    font-size: 1rem;
    margin-right: 10px;
  }
  a.edit-more-colors {
    font-size: 0.75rem;
  }
  .bg-color-circle {
    margin-bottom: 10px;
  }
  .content-section {
    display: block;
  }
  .bg-col {
    margin-top: 10px;
  }
  .colors {
    width: 85%;
  }
  .sketch-picker {
    width: 175px ;
  }
}


