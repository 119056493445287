


main.content {
    padding: 0px;
}

.other-content{
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 90%;
    margin: 20px auto;
    justify-content: space-between;
}


.toolpage {
display: flex;
flex-direction: column;
align-items: center;
padding: 20px;
background-color: #ffffff;
border-radius: 10px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
width: 90%;
margin: 20px auto;
}

.toolpage h1 {
font-size: 1rem;
margin-bottom: 20px;
text-align: center;
}

.toolpage .file-uploader {
margin-bottom: 20px;
width: 100%;
display: flex;
justify-content: center;
}

.toolpage .svg-display {
margin-bottom: 20px;
width: 100%;
display: flex;
justify-content: center;
}

.toolpage .color-selector,
.toolpage .color-palette {
width: 100%;
margin-bottom: 20px;
}

.toolpage .color-selector {
display: flex;
flex-wrap: wrap;
gap: 10px;
}

.toolpage .color-palette {
border: 1px solid #ddd;
border-radius: 8px;
padding: 10px;
background-color: #f0f2f5;
}

.toolpage .color-palette h3 {
font-size: 1.5rem;
margin-bottom: 10px;
color: #444;
}

.toolpage .color-palette .color-item {
display: inline-block;
width: 40px;
height: 40px;
margin: 5px;
border-radius: 5px;
border: 1px solid #ccc;
cursor: pointer;
}

.toolpage .color-item:hover {
opacity: 0.8;
}

.toolpage .color-item.selected {
border: 2px solid #000;
}

.dislaysvg ,.color-combitnation{
    width: 50%;
    margin: 20px auto;
}


.color-combitnation{
    width: 49% !important;
}

.separator2 {
    width: 1px;
    height: 10rem;
    background-color: #e7e7e7;
    margin: 15px 20px;
  }
  
.content-section{
    margin: 0px;
    padding: 0px;
}

.format-selection{
    margin: 0px;
}
/* Responsive Design */
@media (max-width: 768px) {
.toolpage {
    padding: 15px;
}

.toolpage h1 {
    font-size: 2rem;
}

.toolpage .file-uploader,
.toolpage .svg-display {
    width: 100%;
}
}
