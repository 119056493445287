

/* Styling for the file uploader container */
.fileupload {
    border: 2px dashed #ccc;
    position: relative;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    border: 2px dashed #ccc;
    cursor: pointer;
    text-align: center;
    border-radius: 10px;
    background-color: #f8f8f8;
    transition: border-color 0.3s, background-color 0.3s;
  }


/* Close button styling */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff4d4d;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: #ff1a1a;
}

/* Image preview area styling */
.tool-image-preview {
  display: block;
  margin: 20px 0;
  max-width: 100%;
  height: auto;
  border-radius: 15px;
  overflow: hidden;
}

/* Text for displaying image size */
.image-size-info {
  font-size: 14px;
  color: #555;
  margin-top: 10px;
}

/* Input styling for file upload */
.file-input {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Styling for the upload text and info */
.file-upload-text {
  text-align: center;
  font-size: 16px;
  color: #333;
}

.file-upload-info {
  text-align: center;
  font-size: 14px;
  color: #777;
  margin-top: 5px;
}

/* Styling for the uploaded file name */
.uploaded-file-name {
  text-align: center;
  font-size: 14px;
  color: #333;
  margin-top: 10px;
}

  
  .fileupload:hover {
    border-color: #888;
    background-color: #eaeaea;
  }
  
  /* Styling for the file input */
  .file-input {
    display: block;
    margin: 0 auto;
  }
  
  /* Styling for the "or" text */
  .file-upload-text {
    margin: 10px 0;
    font-size: 1.2rem;
    color: #555;
  }
  
  /* Styling for the informational text */
  .file-upload-info {
    font-size: 1rem;
    color: #666;
    margin-bottom: 10px;
  }
  
  /* Styling for the uploaded file name */
  .uploaded-file-name {
    margin-top: 10px;
    font-weight: bold;
    color: #333;
  }
  